// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { ParametriCarrelloItem } from "@/models/carrello/parametriCarrello";
import { parametriPreventivatoreItem } from "@/models/carrello/parametriPreventivatore";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import { TipologieProdotto } from "@/models/prodotti/tipologieProdotto";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { watch } from "fs";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import carrelloCategoria from "./carrelloCategoria.vue";

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio,
			prodotti: (state: any) => state.prodotti,
			width: (state: any) => state.width,
			categoria: (state: any) => state.categoria
		})
	},

	methods: {
		...mapMutations(["setDatiViaggio"])
	}
})
export default class parametriCarrello extends baseContextComponent {
	min = new Date(2022, 4, 1);
	max = new Date(2022, 4, 10);
	// @Prop({ default: () => null }) section: string;
	@Prop({ default: () => null }) buttonClick: any;
	@Prop({ default: () => 2 }) gridColumns: number;
	@Prop({ default: () => "Trova Polizze" }) textButton: string;
	@Prop({ default: () => true }) showImportoViaggio: boolean;
	//@Prop({ default: () => false }) readonly: boolean;
	@Prop({ default: () => "" }) title: string;
	@Watch("showImportoViaggio", { immediate: true })
	onShowImportoViaggioPropChange(newValue): void {
		if (this.parametriItem != null) this.parametriItem.importoViaggio = null;
	}
	// get textButton() {
	// 	if (this.section == 'listaProdotti') return 'Ricalcola';
	// 	else return "Trova Prodotti";
	// }
	// get isOneColumn(): boolean {
	// 	return (this.width > 1023 || this.smAndDown) && this.isListaProdotti;
	// }
	defaultValue = new Date(2022, 4, 15);
	readonly: boolean = false;
	toggleDescriptionDestinazioni: boolean = false;
	toggleDescriptionEta: boolean = false;

	get columsnCss(): string {
		if (this.gridColumns == 1) return "clolumns-1";
		if (this.gridColumns == 4) return "clolumns-4";
		return "clolumns-2";
	}

	destinazioniStati: TextValueItem[] = [];
	destinazioniGruppiStati: TextValueItem[] = [];
	nazionalita: TextValueItem[] = [];
	//eta: TextValueItem[] = [{value: 1, text:"Entro i 70"},{value: 2, text:"Tra 70 e 75"},{value: 3, text:"Tra 75 e 90"},{value: 4, text:"Oltre i 90"}];
	eta: TextValueItem[] = [];
	tipologieProdotti: TipologieProdotto[] = [];
	parametriItem: parametriPreventivatoreItem = new parametriPreventivatoreItem();
	tipologiaHide: boolean = false;
	labelImportoViaggio: string = "Importo Viaggio a Persona";
	datiViaggio!: datiViaggio;
	width!: number;
	categoria!: carrelloCategoria;
	prodotti!: associaDistributoriPacchettiExtended[];
	setDatiViaggio!: (showPreferiti: datiViaggio) => void;

	numPartecipantiFocus: boolean = false;
	importoViaggioFocus: boolean = false;
	inizioViaggioFocus: boolean = false;
	fineViaggioFocus: boolean = false;

	paramsReadOnly: boolean = true;

	created() {
		this.parametriItem = (this.datiViaggio || {}).parametri || new parametriPreventivatoreItem();

		var readonly = this.$route.query.readonly as string;
		if (readonly && readonly == "true") {
			this.readonly = true;
		}

		this.getData();
	}

	// get isListaProdotti() {
	// 	if (this.section == 'listaProdotti') return true;
	// 	else return false;
	// }

	mounted() {
		var self = this;
		this.$root.$on("refreshParametriDaStore", () => {
			self.refreshParametriDaStore();
		});
	}

	beforeDestroy() {
		this.$root.$off("refreshParametriDaStore");
	}

	refreshParametriDaStore() {
		this.parametriItem = (this.datiViaggio || {}).parametri || new parametriPreventivatoreItem();
		// this.getData();
	}

	@Watch("categoria", { immediate: true })
	updateCategoria() {
		//this.tipologiaHide = this.categoria && this.$router.currentRoute.name == "listaProdotti";
		this.tipologiaHide = this.$router.currentRoute.name != "preventivatore";
		if (this.tipologiaHide) this.parametriItem.tipologiaPolizza = null;
	}

	@Watch("prodotti", { immediate: true })
	updateProdotti() {
		//this.tipologiaHide = this.categoria && this.$router.currentRoute.name == "listaProdotti";
		this.tipologiaHide = this.$router.currentRoute.name != "preventivatore";
		if (this.prodotti.length) {
			var allImbarcazioni = true,
				allAlloggi = true;
			for (var i = 0; i < this.prodotti.length; i++) {
				var p = this.prodotti[i];
				!(p.extraInfo || []).find(x => x.labelID === 3) && (allImbarcazioni = false);
				!(p.extraInfo || []).find(x => x.labelID === 9) && (allAlloggi = false);
			}
			// if( allImbarcazioni)
			// 	this.labelImportoViaggio = "Importo Viaggio per Imbarcazione";
			// else if( allAlloggi)
			// 	this.labelImportoViaggio = "Importo Viaggio per Alloggio";
			// else
			this.labelImportoViaggio = "Importo Viaggio a Persona";
		} else this.labelImportoViaggio = "Importo Viaggio a Persona";
	}

	getData() {
		var self = this;
		api.getDatiDropDown(api.endpoint.DROPDOWN.GRUPPI_STATI).then(res => {
			self.destinazioniGruppiStati = res;
		});
		api.getDatiDropDown(api.endpoint.DROPDOWN.STATI_PREVENTIVATORE).then(res => {
			self.destinazioniStati = res;
		});
		api.getDatiDropDown(api.endpoint.DROPDOWN.NAZIONALITA).then(res => {
			self.nazionalita = res;
		});
		api.get(api.endpoint.DROPDOWN.TIPOLOGIE_PRODOTTI_VISIBILI).then(res => {
			self.tipologieProdotti = res;
		});
		api.getDatiDropDown(api.endpoint.DROPDOWN.ETA).then(res => {
			self.eta = res;
		});
	}

	updateParametri() {
		this.datiViaggio.parametri = this.parametriItem;
		this.setDatiViaggio(this.datiViaggio);
	}

	onKeyDownImporto(e) {		
		if( e.which === 190 || e.which === 110 ){
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	}

	buttonClickLocal() {
		if (this.isPreventivatorePage) {
			this.readonly = false;

			if (!this.isValidParams()) return;
		} else {
			this.readonly = true;
		}

		this.paramsReadOnly = true;

		if (this.buttonClick) {
			this.buttonClick();
		}
	}

	isValidParams() {
		var err = [];

		if (!this.parametriItem.inizioViaggio) err.push("Data Inizio Viaggio richiesta");

		if (!this.parametriItem.fineViaggio) err.push("Data Fine Viaggio richiesta");

		if (!this.parametriItem.destinazioneStato || (this.parametriItem.destinazioneStato && !(this.parametriItem.destinazioneStato as any).value)) err.push("Destinazione richiesta");

		if (!this.parametriItem.eta || (this.parametriItem.eta && !(this.parametriItem.eta as any).value)) err.push("Età richiesta");

		//if (!this.parametriItem.nazionalita) err.push("Area di partenza richiesta");

		if (!this.parametriItem.numeroPartecipanti) err.push("Numero Partecipanti richiesto");

		if (
			this.parametriItem.tipologiaPolizza &&
			((this.parametriItem.tipologiaPolizza as any).value == 5 || (this.parametriItem.tipologiaPolizza as any).value == 6) &&
			!this.parametriItem.importoViaggio
		)
			err.push("Importo Viaggio richiesto");

		for (var i = 0; i < err.length; i++) {
			this.$bvToast.toast(err[i], { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
		}

		return err.length == 0;
	}

	routerGoBack() {
		this.$router.go(-1);
	}

	get isListaProdotti(): boolean {
		return this.$router.currentRoute.name == "listaProdotti";
	}

	get isPreventivatorePage(): boolean {
		return this.$router.currentRoute.name == "preventivatore";
	}

	get cannotChangeParams(): boolean {
		return this.readonly && this.paramsReadOnly;
	}
}
