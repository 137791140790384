import { render, staticRenderFns } from "./complementariMobile.vue?vue&type=template&id=220c2306&scoped=true&"
import script from "../components/complementariMobile.vue.ts?vue&type=script&lang=ts&"
export * from "../components/complementariMobile.vue.ts?vue&type=script&lang=ts&"
import style0 from "./complementariMobile.vue?vue&type=style&index=0&id=220c2306&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220c2306",
  null
  
)

export default component.exports