import { render, staticRenderFns } from "./complementari.vue?vue&type=template&id=029a6ca9&scoped=true&"
import script from "../components/complementari.vue.ts?vue&type=script&lang=ts&"
export * from "../components/complementari.vue.ts?vue&type=script&lang=ts&"
import style0 from "./complementari.vue?vue&type=style&index=0&id=029a6ca9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "029a6ca9",
  null
  
)

export default component.exports