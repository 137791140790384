import gridComponentBase from "../components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import api from "@/services/api";

const _ENDPONT = api.endpoint.MY_OVERINS.COMMERCIALI_DISTRIBUTORI_DETTAGLIO;

@Component
export default class CommercialeDistributoreDettaglioPage extends gridComponentBase {
	@Prop({ default: () => null })
	dataDa: Date;
	@Prop({ default: () => null })
	dataA: Date;
	@Prop({ default: () => 0 })
	distributoreId: number;

	aggregates: any[] = [
		{ field: "fatturato", aggregate: "sum" },
		{ field: "numeroAcquisti", aggregate: "sum" },
		{ field: "commissioniCommerciale", aggregate: "sum" },
		{ field: "commissioniDistributore", aggregate: "sum" }
	];
	kendop: any = kendo;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "pacchetto", title: "Pacchetto", headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "categoria", title: "Categoria", headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "fornitore", title: "Fornitore", headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{
				field: "commissioniCommerciale",
				title: "Commiss. Commerc.",
				width: 150,
				format: "{0:c}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'c')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'c')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCommissioniCommercialiCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "commissioniDistributore",
				title: "Commiss. Distrib.",
				width: 150,
				format: "{0:c}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'c')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'c')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCommissioniDistributoreCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "fatturato",
				title: "Fatturato",
				width: 150,
				format: "{0:c}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'c')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'c')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterFatturatoCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "numeroAcquisti",
				title: "Numero Acquisti",
				width: 150,
				format: "{0:n2}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterNumeroAcquistiCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "ultimoAcquisto",
				title: "Ultimo Acquisto",
				width: 150,
				type: "date",
				headerType: "daterange",
				format: "{0:dd/MM/yyyy}",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true
			}
		];
	}

	created() {
		this.getData();
	}

	getData() {
		let startDate = moment()
			.utc()
			.add(-1, "month")
			.format();

		/*if (this.dataDa) {
			startDate = moment(this.dataDa)
				.utc()
				.format();
		}*/

		if (this.dataDa) {
			startDate = moment(this.dataDa).format("YYYY-MM-DD");
		}

		let endDate = moment()
			.utc()
			.format();
		/*if (this.dataA) {
			endDate = moment(this.dataA)
				.utc()
				.format();
		}*/

		if (this.dataA) {
			endDate = moment(this.dataA).add(1, "days").format("YYYY-MM-DD");
		}

		let soloAttivi = this.soloAttivi;

		this.getGridData(`${_ENDPONT}?dataDa=${startDate}&dataA=${endDate}&distributoreId=${this.distributoreId}`);
	}

	exportExcel() {
		let startDate = moment()
			.utc()
			.add(-1, "month")
			.format();
		if (this.dataDa) {
			startDate = moment(this.dataDa)
				.utc()
				.format();
		}

		let endDate = moment()
			.utc()
			.format();
		if (this.dataA) {
			endDate = moment(this.dataA)
				.utc()
				.format();
		}

		this.exportGridExcel(`${_ENDPONT}?dataDa=${startDate}&dataA=${endDate}&distributoreId=${this.distributoreId}`);
	}


	getCommissioniCommercialeTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function(item) {
				return item.commissioniCommerciale ? item.commissioniCommerciale : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	getCommissioniDistributoreTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function(item) {
				return item.commissioniDistributore ? item.commissioniDistributore : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	getNumeroAcquistiTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function(item) {
				return item.numeroAcquisti ? item.numeroAcquisti : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	getFatturatoTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function(item) {
				return item.fatturato ? item.fatturato : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}
}
